
import "./App.css";
import React from "react";
import { ThemeProvider } from "@mui/material";
import Header from "./components/header/header";
import theme from "./components/theme";
import Hero from "./components/hero/hero";
import Intro from "./components/intro/intro";
import SectionsContainer from "./components/companies/sectioncontainer";
import Footer from "./components/footer/footer";
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
    <ThemeProvider theme={theme}>
    <div className="App">
      <Header />
      <Hero/>
      <Intro/>
      <SectionsContainer/>
      <Footer/>
    </div>
    </ThemeProvider>
    </Router>
  );
}

export default App;
