import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: #212121; 
  color: white;
  text-align: center;
  padding: 20px 0;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>Copyright © Urban Prime Group {currentYear}. All rights reserved</StyledFooter>
  );
}

export default Footer;