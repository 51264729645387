import React from 'react';
import { Grid, Box } from '@mui/material';
import { keyframes, css } from '@emotion/react';
import styled from '@emotion/styled';

import Groundscapes from '../../images/Groundscapes.png';
import Roofscapes from '../../images/Roofscapes.png';
import Renfab from '../../images/Renfab.png';
import Primeseal from '../../images/Primeseal.png';

import RenfabBG from '../../images/RenfabBG.png'
import RoofscapesBG from '../../images/RoofscapesBG.png'
import PrimesealBG from '../../images/PrimesealBG.png'
import GroundscapesBG from '../../images/GroundscapesBG.png'

import { useNavigate } from 'react-router-dom';

const Hero = () => {
    const navigate = useNavigate();

    const handleScroll = (item) => {
        navigate(`/${item}`);
        document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
      };

    const sections = [
      { bg: GroundscapesBG, logo: Groundscapes, name: 'Urban Groundscapes' },
      { bg: RoofscapesBG, logo: Roofscapes, name: 'Urban Roofscapes' },
      { bg: RenfabBG, logo: Renfab, name: 'Renfab' },
      { bg: PrimesealBG, logo: Primeseal, name: 'Primeseal' },
    ];

  
    const StyledBox = styled(Box)(({ theme, bg }) => ({
        position: 'relative',
        height: 250,
        [theme.breakpoints.down('sm')]: {
            height: 150, // or whatever height you want on mobile
          },
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundImage: `url(${bg})`,
          backgroundPosition: '0% 50%',
          backgroundSize: 'cover',
          transition: 'background-position 2s',
          zIndex: 1,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)', // Overlay
          zIndex: 2,
        },
        '&:hover::before': {
          backgroundPosition: '100% 50%',
        },
    }));

    return (
      <Grid container spacing={0}>
        {sections.map((section, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
           <StyledBox bg={section.bg} onClick={() => handleScroll(section.name)}>
  <Box 
    sx={{ 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      position: 'relative',
      cursor: 'pointer',
      zIndex: 3, // Make sure this is higher than the zIndex of the overlay
    }}
  >
    <img src={section.logo} alt="Logo" />
  </Box>
</StyledBox>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  export default Hero;