import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, useMediaQuery, useTheme, Box, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../images/Logo.png"
import { useNavigate } from 'react-router-dom';
import "./headerstyles.css"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  

  const handleScroll = (item) => {
    navigate(`/${item}`);
    document.getElementById(item).scrollIntoView({ behavior: 'smooth' });
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItems1 = ["Intro", "Urban Groundscapes", "Urban Roofscapes"];
  const menuItems2 = ["Renfab", "Primeseal", "Contact"];

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#fff', boxShadow: 'none', position: 'sticky', top: '0', zIndex: '9999' }}>
        <Toolbar>
          {isMobile ? (
            <Box display="flex" justifyContent="space-between" width="100%">
              <img src={Logo} style={{ alignSelf: 'center', maxWidth: "130px" }}/>
              <IconButton edge="start" color="black" aria-label="menu" onClick={toggleMenu}>
                <MenuIcon />
              </IconButton>
            </Box>
          ) : (
            <Box display="flex" justifyContent="space-around" alignItems="center" width="100%">
            <Box display="flex" justifyContent="space-around" flexGrow={1}> {/* Left menu */}
            {menuItems1.map((item, index) => (
             <Typography 
             variant="h6" 
             className="gradient-text"
             sx={{ 
               color: '#000', 
               marginLeft: theme.spacing(2), 
               fontSize: '0.875rem', 
               cursor: 'pointer'
             }}
             onClick={() => handleScroll(item)}
           >
             {item}
           </Typography>
))}
            </Box>
            <Box> {/* Logo */}
            <img src={Logo} style={{ alignSelf: 'center', maxWidth: "130px", cursor: "pointer" }} onClick={() => window.scrollTo(0, 0)} />
            </Box>
            <Box display="flex" justifyContent="space-around" flexGrow={1}> {/* Right menu */}
            {menuItems2.map((item, index) => (
        <Typography 
        variant="h6" 
        className="gradient-text"
        sx={{ 
          color: '#000', 
          marginLeft: theme.spacing(2), 
          fontSize: '0.875rem', 
          cursor: 'pointer'
        }}
        onClick={() => item === "Contact" ? handleOpenDialog() : handleScroll(item)}
      >
  {item}
</Typography>
))}
            </Box>
          </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer 
  anchor={isMobile ? 'left' : 'top'} 
  open={isOpen} 
  onClose={toggleMenu} 
  sx={{ 
    width: '100vw', 
    height: '100vh',
    '& .MuiDrawer-paper': {
      width: '100vw',
      height: '100vh'
    }
  }}
>
<List sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%', padding: theme.spacing(2) }}>
  {menuItems1.concat(menuItems2).map((item, index) => (
    <ListItem button key={index} onClick={() => {
      toggleMenu(); 
      item === "Contact" ? handleOpenDialog() : handleScroll(item);
    }}>
      {item}
    </ListItem>
  ))}
</List>
</Drawer>
<Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>Contact Us</DialogTitle>
  <DialogContent>
  <TextField 
  autoFocus 
  margin="dense" 
  label="Name" 
  type="text" 
  fullWidth 
  sx={{ 
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black', // Change border color to black
      },
    },
    '& label.Mui-focused': {
      color: 'black', // Change label color to black
    },
  }}
/>
  <TextField margin="dense" label="Email Address" type="email" fullWidth  sx={{ 
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black', // Change border color to black
      },
    },
    '& label.Mui-focused': {
      color: 'black', // Change label color to black
    },
  }} />
    <TextField margin="dense" label="Message" type="text" multiline rows={4} fullWidth  sx={{ 
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black', // Change border color to black
      },
    },
    '& label.Mui-focused': {
      color: 'black', // Change label color to black
    },
  }}/>
  </DialogContent>
  <DialogActions>
  <Button onClick={handleCloseDialog} sx={{ color: 'black' }}>Cancel</Button>
<Button onClick={handleCloseDialog} sx={{ color: 'black' }}>Submit</Button>
  </DialogActions>
</Dialog>
    </>
  );
};

export default Header;