import React from 'react';
import styled from 'styled-components';

const IntroContainer = styled.div`
  background-color: #212121; // Changed to #212121
  color: white;
  text-align: center;
  padding: 80px 30%;
`;

const IntroHeading = styled.h1`
  position: relative;
  display: inline-block;
  font-size: 16px; 
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: uppercase;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 15px;
    height: 2px;
    background: linear-gradient(90deg, #2C9942, #94BF31);
  }

  &:before {
    right: 100%;
    margin-right: 10px;
  }

  &:after {
    left: 100%;
    margin-left: 10px;
  }
`;

const IntroParagraph = styled.p`
  color: white;
`;

const Intro = () => (
  <IntroContainer id="Intro">
    <IntroHeading>Intro</IntroHeading>
    <IntroParagraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id felis et ipsum bibendum ultrices. Morbi vitae pulvinar velit. Sed aliquam dictum sapien, id sagittis augue malesuada eu.</IntroParagraph>
  </IntroContainer>
);

export default Intro;