import React, { useState } from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
  display: flex;
  height: 300px; // Set a fixed height

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    background: ${({ imageUrl }) => `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.7)), url(${imageUrl}) no-repeat center center/cover`
  }
`;

const ImageContainer = styled.div`
  flex: ${({ isHovered }) => (isHovered ? 3 : 7)};
  background: ${({ imageUrl }) => `url(${imageUrl}) no-repeat center center/cover`};

  @media (max-width: 768px) {
    display: none;
  }
`;


const ContentContainer = styled.div`

width: ${({ isHovered }) => (isHovered ? '50%' : '30%')};
transition: width 3s ease;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 80px;
  transition: all 1s ease;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    padding: 20px;
  }
`;


const Title = styled.h2`
  position: relative;
  top: ${({ isHovered }) => (isHovered ? '20%' : '50%')};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 1.5s ease;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -20px;
    width: 15px;
    height: 2px;
    background: linear-gradient(90deg, #2C9942, #94BF31);
  }

  @media (max-width: 768px) {
    top: 0;
  }
`;

const Description = styled.p`
  color: black;
  opacity: ${({ isHovered }) => (isHovered ? 1 : 0)}; 
  transition: opacity 2s ease;
  text-align: left;

  @media (max-width: 768px) {
    opacity: 1;
  }
`;
const VisitSiteButton = styled.a`
  color: white;
  text-decoration: none;
  opacity: 1;
  font-weight: bold;
  transition: opacity 0.3s ease;
  background: linear-gradient(90deg, #2C9942, #94BF31);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    opacity: 1;
  }
`;

const Section = ({ title, description, reverse, image }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <SectionContainer 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
      style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}
      imageUrl={image}
      id={title}
    >
      <ImageContainer isHovered={isHovered} imageUrl={image} />
      <ContentContainer isHovered={isHovered}>
        <Title isHovered={isHovered}>{title}</Title>
        <Description isHovered={isHovered}>{description}</Description>
        <VisitSiteButton isHovered={isHovered} href="#">Visit Site {'>'}</VisitSiteButton>
      </ContentContainer>
    </SectionContainer>
  );
};
export default Section;