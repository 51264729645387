import React from 'react';
import Section from './companieslayout';
import RenfabBG from '../../images/RenfabBG.png'
import RoofscapesBG from '../../images/RoofscapesBG.png'
import PrimesealBG from '../../images/PrimesealBG.png'
import GroundscapesBG from '../../images/GroundscapesBG.png'

const sections = [
  { title: 'Urban Groundscapes', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id felis et ipsum bibendum ultrices. Morbi vitae pulvinar velit. Sed aliquam dictum sapien, id sagittis augue malesuada eu.', image: GroundscapesBG },
  { title: 'Urban Roofscapes', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id felis et ipsum bibendum ultrices. Morbi vitae pulvinar velit. Sed aliquam dictum sapien, id sagittis augue malesuada eu.', image: RoofscapesBG },
  { title: 'Renfab', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id felis et ipsum bibendum ultrices. Morbi vitae pulvinar velit. Sed aliquam dictum sapien, id sagittis augue malesuada eu.', image: RenfabBG },
  { title: 'Primeseal', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id felis et ipsum bibendum ultrices. Morbi vitae pulvinar velit. Sed aliquam dictum sapien, id sagittis augue malesuada eu.', image: PrimesealBG },
];

const SectionsContainer = () => (
  <div>
    {sections.map((section, index) => (
      <Section 
        key={index} 
        title={section.title} 
        description={section.description} 
        reverse={index % 2 !== 0} 
        image={section.image}
      />
    ))}
  </div>
);

export default SectionsContainer;